.testimonial--section{
    margin-bottom: 100px;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.testimonial--header{
    padding: 10px 10px;
    z-index: 20;
    background: transparent;
    /* margin: 150px 50px; */
    text-align: center;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 48px;
    line-height: 16px;
    letter-spacing: 0.002em;
    text-transform: capitalize;   
    margin-bottom: 20vh;
}

.testimonial--elements{
    display: flex;
    max-width: fit-content;
    margin: auto;
}

.testimonial--data{
    padding: 0 40px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: 0.002em;
    text-transform: capitalize;
    text-align: justify;
}

.testimonial--name{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: 0.002em;
    text-transform: capitalize;
    padding: 0px 40px;
    padding-top: 30px;
}

.testimonial--desc{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.002em;
    text-transform: capitalize;
    color: #4D7DBE;
    padding: 0px 40px;
    padding-bottom: 30px;
}

.testimonial--card{
    max-width: 500px;
    max-height: 200px;
    background: #FFFFFF;
    box-shadow: 0px 49px 74px -24px rgba(0, 0, 0, 0.15);
    margin: auto;
    margin: 0 4vh;
    padding-top: 30px;
}

@media screen and (max-width: 900px){
    .testimonial--elements{
        display: list-item;
    }

    .testimonial--card{
        margin: auto;
        margin-bottom: 150px;
        max-width: 400px;
        max-height: 100px;
    }

    .testimonial--header{
        margin: 150px 0;
        font-size: 25px;
        margin-bottom: 35vh;
    }


}

.testi--containers{
    /* background-image: url('../../../../public/images/bg_bottom.svg'); */
    /* background: transparent; */
    z-index: 20;
}

