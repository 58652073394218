.banner--container{
    display: flex;
    margin-bottom: 200px;
    /* background-image: url('../../../../public/images/bg_bottom.svg'); */
}

.banner--data{
    /* max-width: fit-content; */
    max-width: 700px;
    max-height: 700px;
    padding: 0 24px;
    margin: auto;
}

.topline{
    font-style: "Roboto";
    font-weight: bold;
    font-size: 58px;
    line-height: 82px;
    letter-spacing: 0.002em;    
}

.subtitle{
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    line-height: 141.8%;
    letter-spacing: 0.002em;
    padding-bottom: 30px;
}

.trans--button{
    display: inline-block;
}

.trans--button p{
    display: inline-block;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
}


.banner--graphics img{
    height: 500px;
    width: 200px;
    display: inline-flex;
    object-fit: cover;
    padding: 0px 5px;
    border-radius: 15px;
    box-shadow: 0px 71px 40px -12px rgba(0, 0, 0, 0.15);
}

.img-b{
    padding-top: 60px;
}

.banner--graphics{
    display: flex;
    margin: auto;
    padding: 0 20px;
    margin-top: 80px;
}


.isRed{
    color: #ffffff;
    background:transparent;
    text-decoration: none;
}

.isRed:hover{
    color: #0c0909;
}

.isWhite {
    background: transparent;
    text-decoration: none;
    color: #000;
}


.isWhite:hover{
    color: #d00c0c;
}


@media screen and (max-width: 900px){
    .banner--container{
        justify-content: center;
        align-items: center;
        margin-top: 50px;
    }

    .topline{
        font-size: 50px;
        font-style: normal;
        font-weight: bold;
        line-height: 75px;
        letter-spacing: 0.002em;
    }

    .banner--graphics{
        display: none;
    }


}