.sponsor--header{
    padding: 10px 10px;
    z-index: 10;
    background: transparent;
    /* margin: 150px 50px; */
    text-align: center;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 48px;
    line-height: 16px;
    letter-spacing: 0.002em;
    text-transform: capitalize;   
    margin-bottom: 10px;
}

@media screen and (max-width: 900px) {
    .sponsor--header {
        margin: 00px 0;
        font-size: 30px;
        /* margin-bottom: 40vh; */
    }
}

.client{
    text-align: center;
}
.client ul{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    list-style: none;
	padding: 0;
	margin: 0 auto;
}
.client ul li{
    width: calc(100% / 3);
    box-sizing: border-box;
    text-align: center;
    margin: 10px 0;
    align-items: center;

}
.client ul li img{
    width: 350px;
    height: 350px;
	padding: 50px;
    box-shadow: 0px 71px 40px -12px rgba(0, 0, 0, 0.15);
    border-radius: 15px;
    object-fit: contain;
    margin: 0 auto;
}

@media (max-width: 600px) {
    .client{
        display: none;
    }
    .client ul {
        flex-direction: column;
        align-items: center;
    }

    .client ul li{
        flex: 1 0 auto;
        align-items: center;
        display: block;
    }

    .client ul li img{
        width: 250px;
        height: 250px;
        display: block;
        margin: 0 auto;
        align-items: center;
        align-self: center;
    }
}


/* For mobile */
.mlist {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
    margin: 0 auto;
}

.mlist li {
    flex: 1 0 33%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.mlist li img {
    /* max-width: 100%;
    height: auto;*/
    width: 350px;
    height: 350px;
    padding: 50px;
    box-shadow: 0px 71px 40px -12px rgba(0, 0, 0, 0.15);
    border-radius: 15px;
    object-fit: contain;
    margin: 0 auto;
}

@media (max-width: 600px) {
    .mlist {
        flex-direction: column;
    }

    .mlist li {
        flex: 1 0 auto;
    }

    .mlist li img {
        /* max-width: 350px;
        max-height: 350px; */
        width: 250px;
        height: 250px;
        display: block;
        margin: 0 auto;
    }
}

@media only screen and (min-width: 600px) {
    .mlist{
        display: none;
    }
    .mlist li img {
        display: none;
    }
}
