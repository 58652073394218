.info--elements{
    display: flex;
    max-width: fit-content;
    max-height: fit-content;
    justify-content: center;
    align-items: center;
    padding: 0 24px;
    margin: auto;
    margin-bottom: 150px;
}

.info--data{
    /* max-width: fit-content; */
    max-height: 180px;
    margin: auto;
    padding: 0 24px;
}


.info--vector img{
    margin-right: 0%;
    margin-left: 300px;
}

.info--topline{
    font-family: "Roboto";
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    color: #DC1D28;
    margin-bottom: 20px;
}

.info--p{
    font-style: normal;
    font-weight: bold;
    font-size: 48px;
    line-height: 58px;
    letter-spacing: 0.002em;
}

.info--p2{
    padding-top: 14px;
    font-family: sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: 0.002em;
}

@media screen and (max-width: 900px){
    .info--elements{
        display: list-item;
        margin-bottom: 350px;
    }

    .info--data{
        max-width: 700px;
    }

    .info--p{
        font-size: 40px;
    }

    .info--vector{
        display: none;
    }
}
