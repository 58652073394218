.blog--container{
	padding: 30px 60px;
	margin: auto;
	width: 100%;
	max-width: 900px;
	min-height: 900px;
	display: flex;
	flex-direction: column;
	/* justify-content: center; */
	text-align: left;
	border-radius: 10px;
	box-shadow: 0 50px 70px -20px rgba(0, 0, 0, 0.8);
}

.blog--container img{
	/* box-shadow: 0px 71px 40px -12px rgba(0, 0, 0, 0.15); */
    border-radius: 15px;
	display: block;
	margin-left: auto;
	margin-right: auto;
	width: 75%;
}

.blog--data img{
	display: block;
	margin-left: auto;
	margin-right: auto;
	width: 75%;
}

.img--wrapper{
	max-width: 900px;
}

.blog--title{
    font-family: sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 48px;
	margin: 30px 0px;
	color: #DC1D28;
}

.blog--author{
    margin: 10px 0;
}

.blog--author{
    display: flex;
	font-size: 20px;
}

.blog--author p{
	display: flex;
	margin: auto;
}

.footer--container{
	margin: 10px 0;
	margin-top: 50px;
}

.footer--container a{
	color: black;
	text-decoration:none;
	display: flex;
	margin: auto;	
}


.contact--footer{
	font-size: 20px;
	display: flex;
	margin: auto;
}

.contact--footer p{
	display: flex;
	margin: auto;
}


.section--header{
	font-size: 34px;
	color: #DC1D28;
	/* text-align: left; */
	font-weight: bold;
}

.p--icon{
	margin: 0 10px;
}

.p--icon--f{
	margin: 0 4px;
}

.intro--data{
	text-align: left;
    font-family: sans-serif;
    font-style: normal;
    font-size: 20px;
	/* margin: 30px 0px; */
	margin-top: 30px;
	color: #000000;
}

.program--snippet{
	background-color: transparent;
}

.banner--img{
	margin: 30px 0;
}

.blog--data{
	text-align: left;
    font-family: sans-serif;
    font-style: normal;
    font-size: 20px;
	color: #000000;
}

.blog--para{
	text-align: left;
    font-family: sans-serif;
    font-style: normal;
    font-size: 20px;
	/* margin-bottom: 30px; */
	color: #000000;
}

.section--name{
	text-align: left;
    font-family: sans-serif;
    font-style: normal;
    font-size: 30px;
	margin: 10px 0;
	color: #DC1D28	
}

.footer--topline{
	text-align: center;
    font-family: sans-serif;
    font-style: normal;
    font-size: 30px;
	margin: 30px 0;
	color: #DC1D28	
}

.blog--data img{
	/* margin: 20px 0px; */
}

@media screen and (max-width: 700px){
	.blog--author{
		font-size: 18px;
	}

	.contact--footer{
		font-size: 14px;
	}
}

@media screen and (max-width: 500px){
	.blog--author{
		font-size: 12px;
	}
	.blog--title{
		font-size: 28px;
	}
	.blog--container{
		max-width: 100vh;
	}

	.blog--container img{
		width: 100%;
	}

	.blog--para{
		font-size: 16px;
	}

	.section--name{
		font-size: 20px;
	}
	.contact--footer{
		font-size: 10px;
	}
}
