footer {
    background-color: #b6b4b4;
    padding: 50px 0;
    margin-top: 100px;
}

.footer-container {
    background-color: #b6b4b4;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 1200px;
    margin: 0 auto;
}

.footer-item {
    background-color: #b6b4b4;
    flex: 1 1 300px;
    padding: 0 20px;
    margin-bottom: 30px;
}

.footer-item h3 {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
    background-color: #b6b4b4;
}

.footer-item p {
    font-size: 14px;
    margin: 0;
    background-color: #b6b4b4;
}

.footer-item a {
    display: inline-block;
    margin-top: 10px;
    font-size: 14px;
    color: #333;
    text-decoration: none;
    border-bottom: 2px solid #333;
    padding-bottom: 2px;
    background-color: #b6b4b4;
}

.footer-item a:hover {
    color: #0077b5;
    border-bottom-color: #0077b5;
}