.number--group{
    display: flex;
    margin-bottom: 100px;
    margin-top: 150px;
    z-index: 5;
}

.number--element{
    margin: auto;
}

.num{
    font-style: normal;
    font-weight: bold;
    font-size: 64px;
    /* line-height: 75px; */
    line-height: 65px;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    z-index: 1;
}

.line--below{
    width: 93.9px;
    height: 0px;
    left: 101px;
    top: 2502px;
    border: 3px solid #dd0404;
    background-color: #dd0404;
    z-index: 1;
}

.text--below{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: 0.03em;
    text-transform: capitalize;
    z-index: 1;
}


@media screen and (max-width: 900px){
    .number--group{
        display: list-item;
    }

    .number--element{
        width: 50%;
        margin: 0 auto;
        padding-bottom: 20px;
    }
}

