:root {
	--primary: #ffffff;
}

.btn {
  padding: 15px 50px;
  border-radius: 50px;
  margin-right: 12px;
  outline: none;
  border: none;
  cursor: pointer;
  white-space: nowrap;
}

.btn--primary {
	background-color: var(--primary);
	color: #000000;
	border: 1px solid var(--primary);
}

.btn--outline {
	background-color: transparent;
	color: #fff;
	padding: 8px 20px;
	border: 1px solid var(--primary);
	transition: all 0.3s ease-out;
}

.btn--medium {
	padding: 8px 20px;
	font-size: 18px;
}

.btn--large {
	padding: 12px 26px;
	font-size: 20px;
}

.btn--mobile {
	text-align: center;
	border-radius: 4px;
	width: 80%;
	text-decoration: none;
	font-size: 1.5rem;
	background-color: transparent;
	color: #fff;
	padding: 14px 20px;
	border: 1px solid #fff;
	transition: all 0.3s ease-out;
}

.btn--wide {
	padding: 15px 26px;
	border-radius: 50px;
	font-size: 20px;
}


.btn--wide:hover {
  transition: all 0.2s ease-out;
}

.btn-link {
	display: flex;
	justify-content: center;
	align-items: center;
	text-decoration: none;
	padding: 8px 16px;
	height: 100%;
	width: 100%;
}

.mobile--btn{
    margin-right: auto;
    margin-left: auto;
  }

@media screen and (max-width: 768px){
  .mobile--btn{
    margin-right: auto;
    /* margin-left: 60px; */
  }
}

@media screen and (max-width: 768px){
  .btn--wide {
    font-size: 15px;
	padding: 15px 20px;
	}

	.see-through{
		font-size: 15px;
		border: none;
	}
}

@media screen and (max-width: 400px){
	.btn--wide {
	  font-size: 15px;
	  padding: 15px 20px;
	  }
  
	  .see-through{
		  font-size: 15px;
		  border: none;
	  }
  }

.blue {	
	background-color: #276afb;
	color: #fff;
	border: none;
}

.red {
	background-color: #DC1D28;
	color: #ebe9e9;
	border: none;
}

.red:hover{
	background-color: #f12c36;
	color:#0d0404;
	transition: all 0.2s ease-out;
}

.primary {
  background-color: #000000;
  color: #fff;
  border: none;
}

.primary:hover {
  background-color: rgb(43, 42, 42)000;
  color: #ffffff;
  border: none;
}

.see-through{
	/* padding: 15px 30px; */
	/* border-radius: 50px; */
	font-size: 20px;
	background-color: transparent;
	color: #000000;
	border: none;
}

.see-through:hover{
	background-color: #ffffff;
	color: #DC1D28;
	transition: all 0.2s ease-out;
}

.nav--button{
	background-color: #000000;
	color: #ffffff;
	border: none;	
}

