.banner--element img{
    height: 500px;
    width: 200px;
    display: inline-flex;
    object-fit: cover;
    padding: 0px 5px;
    border-radius: 15px;
    box-shadow: 0px 71px 40px -12px rgba(0, 0, 0, 0.15);
}


.big--image{
    width: 600px;
    height: 600px;
    box-shadow: 0px 71px 40px -12px rgba(0, 0, 0, 0.15);
    border-radius: 15px;
}

.vector--image{
    align-items: right;
}

@media screen and (max-width: 1100px){
    .banner--element{
        display: none;
    }

    .big--image{
        width: 300px;
        height: 300px;
    }
}