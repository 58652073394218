.stage--element{
    display: flex;
    margin-bottom: 150px;
    margin-left: 20px;
    /* justify-content: center;
    align-items: center; */
}

.stage--card{
    display: flex;
    margin: auto;
    padding-right: 35vh;
}

.card--parent{
    position: absolute;
    margin: 0px 50px;
    width: 340px;
    height: 145px;
    box-shadow: 0px 49px 74px -24px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    margin-top: 90px;
}

.card--data{
    padding: 30px 30px;
    left: 571px;
    top: 1317px;
}

.card--header{
    font-family: sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: 0.002em;
    color: #DC1D28;
}

.stage--data{
    font-family: sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.002em;
}

@media screen and (max-width: 900px){
    .stage--element{
        display: list-item;
    }

    .card--parent{
        max-width: 250px;
        max-height: 250px;
    }

    .stage--card{
        margin: 50px 0px;
    }
}

