.btn--userAuth {
    font-size: 20px;
	border: none;
	outline: none;
	width: 100%;
	padding: 15px 0;
	color: #ffffff;
	font-size: 1rem;
	border-radius: 50px;
	letter-spacing: 1px;
	background: #DC1D28;
	border: none;
	cursor: pointer;
}


.btn--userAuth:hover {
	color: #eeeeee;
	background-color: #f12c36;
	transition: all 0.2s ease-out;
}

.login {
	width: 100%;
	padding: 20px 10px;
	background-color: #ffffff;
	display: flex;
}

.login .loginContainer {
	padding: 30px 60px;
	margin: auto;
	width: 100%;
	max-width: 420px;
	min-height: 600px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	border-radius: 10px;
	background: #fff;
	box-shadow: 0 50px 70px -20px rgba(0, 0, 0, 0.8);
}

.login .loginContainer label {
	color: #222222;
	margin: 25px 0px 3px 0px;
	display: block;
	font-size: 1rem;
	line-height: 1;
	position: relative;
}

.login .loginContainer .field .placeholder {
	position: absolute;
	top: calc(35% - 2px);
	left: 10px;
	font-size: 1rem;
	color: #aaaaaa;
	transition: 0.4s ease;
	user-select: none;
}

.login .loginContainer .field input {
	width: 100%;
	border: none;
	outline: none;
	border-bottom: 2px solid #aaaaaa;
	font-size: 0.9rem;
	padding: 10px;
	color: #393939;
	letter-spacing: 1px;
	transition: 0.4s ease;
}

.login .loginContainer .field input:valid,
.login .loginContainer .field input:focus {
	border: 1px solid #222222;
	border-radius: 5px;
}

.login .loginContainer .field input:valid + .placeholder,
.login .loginContainer .field input:focus + .placeholder {
	top: -5px;
	left: 10px;
	color: #222222;
	background-color: #fff;
	padding: 0 5px;
	font-size: 0.7rem;
}

.login .loginContainer .btnContainer {
	width: 100%;
	padding: 24px 0;
}

.login .loginContainer .btnContainer p {
	margin: 25px 0 0 0;
	text-align: center;
	color: #222222;
	font-size: 0.8rem;
}

.login .loginContainer .btnContainer p span {
	color: #1c7ed6;
	font-weight: 500;
	font-size: 0.9rem;
	letter-spacing: 0.5px;
	margin-left: 5px;
	cursor: pointer;
	display: block;
	transition: all 400ms ease-in-out;
    text-decoration: none;
}

.login .loginContainer .btnContainer p span:hover {
	color: red;
}

.login .loginContainer .errorMsg {
	color: red;
	font-size: 0.8rem;
	margin: 0.2rem 0 1rem 0;
}

.login .loginContainer .noMsg {
	display: none;
}

.auth__svg-img {
	max-width: 100%;
	margin-top: 0;
	margin-right: 0;
	margin-left: 0px;
	padding-right: 0;
}
.auth__svg__signup-img {
	max-width: 100%;
	margin-top: 0;
	margin-right: 0;
	margin-left: 0px;
	padding-left: 0;
}