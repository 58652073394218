.top--vector{
    position: absolute;
    left: -505px;
    top: -380px;
    /* z-index: -1; */
}

.bottom--vector{
    position: absolute;
    left: -350px;
    top: 2600px;
    /* left: -300px;
    top: 2450px; */
    z-index: 0;
    background: transparent;
}
.bottom--vector2 {
    position: absolute;
    right: -350px;
    top: 2600px;
    /* left: -300px;
    top: 2450px; */
    z-index: 0;
    background: transparent;
}

.about-us{
    display: flex;
}

.about--img{
    margin: auto;
}

.about--data{
    max-width: 540px;
    max-height: 440px;
    margin: auto;
}

.about--img img{
    width: 600px;
    height: 600px;
    box-shadow: 0px 71px 40px -12px rgba(0, 0, 0, 0.15);
    border-radius: 15px;
}

.about--topline{
    font-family: "Roboto";
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    color: #DC1D28;
    /* margin-bottom: 40px; */
}

.about--headline{
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 48px;
    line-height: 56px;
    /* line-height: 24px; */
    letter-spacing: 0.002em;
    text-transform: capitalize;  
    padding-bottom: 20px;  
}

.about--para{
    padding-top: 14px;
    font-family: "Roboto";
    /* font-family: sans-serif; */
    font-style: normal;
    font-weight: 530;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: 0.002em;
    padding-bottom: 60px;
    text-align: justify;
    /* font-family: sans-serif; */
    /* font-family: "Roboto";
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: 0.1em;
    padding-bottom: 60px;   
    text-align: justify; */
}

.about--clickable{
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: 0.015em;
    text-transform: capitalize;
}




.about--clickable img{
    /* padding: 0px 4px; */
    width: 30px;
    height: 40px;
}

.isRed{
    color: #f7f7f7;
    background: transparent;
    text-decoration: none;
}

.isRed:hover{
    color: #000000;
}

@media screen and (max-width: 900px){
    .about-us{
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 100px;
    }

    .about--img img{
        width: 500px;
        height: 500px;
        padding: 0 8px;
        border-radius: 15px;
        margin-bottom: 100px;
    }

    .about--para{
        font-size: 20px;
    }

    .about--data{
        padding: 0px 24px;
    }

    .top--vector{
        display: none;
    }

    .bottom--vector{
        display: none;
    }

    .bottom--vector2{
        display: none;
    }

    .about--headline{
        font-family: "Roboto";
        font-style: normal;
        font-weight: 500;
        font-size: 45px;
        line-height: 52px;
        /* line-height: 24px; */
        letter-spacing: 0.002em;
        text-transform: capitalize;
        padding-bottom: 20px;
    }
}
/* 
.about--data a{
    color: rgb(7, 0, 0);
    text-decoration: none;
} */

@media screen and (max-width: 500px){
    .about--img img{
        width: 400px;
        height: 400px;
        padding: 0 8px;
        border-radius: 15px;
        margin-bottom: 100px;
    }

    .about--data{
        padding: 0px 24px;
    }
}

@media screen and (max-width: 400px){
    .about--img img{
        width: 350px;
        height: 350px;
        padding: 0 8px;
        border-radius: 15px;
        margin-bottom: 100px;
    }

    .about--clickable{
        display: none;
    }
 }


